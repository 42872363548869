// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-en-js": () => import("./../../../src/pages/about-us.en.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-anti-corruption-policy-en-js": () => import("./../../../src/pages/anti-corruption-policy.en.js" /* webpackChunkName: "component---src-pages-anti-corruption-policy-en-js" */),
  "component---src-pages-auditoria-gratis-para-o-seu-site-wordpress-pt-js": () => import("./../../../src/pages/auditoria-gratis-para-o-seu-site-wordpress.pt.js" /* webpackChunkName: "component---src-pages-auditoria-gratis-para-o-seu-site-wordpress-pt-js" */),
  "component---src-pages-aumente-a-visibilidade-do-seu-site-wordpress-pt-js": () => import("./../../../src/pages/aumente-a-visibilidade-do-seu-site-wordpress.pt.js" /* webpackChunkName: "component---src-pages-aumente-a-visibilidade-do-seu-site-wordpress-pt-js" */),
  "component---src-pages-code-ethics-conduct-en-js": () => import("./../../../src/pages/code-ethics-conduct.en.js" /* webpackChunkName: "component---src-pages-code-ethics-conduct-en-js" */),
  "component---src-pages-codigo-etica-conduta-pt-js": () => import("./../../../src/pages/codigo-etica-conduta.pt.js" /* webpackChunkName: "component---src-pages-codigo-etica-conduta-pt-js" */),
  "component---src-pages-complaints-management-policy-en-js": () => import("./../../../src/pages/complaints-management-policy.en.js" /* webpackChunkName: "component---src-pages-complaints-management-policy-en-js" */),
  "component---src-pages-contact-us-en-js": () => import("./../../../src/pages/contact-us.en.js" /* webpackChunkName: "component---src-pages-contact-us-en-js" */),
  "component---src-pages-contacte-nos-pt-js": () => import("./../../../src/pages/contacte-nos.pt.js" /* webpackChunkName: "component---src-pages-contacte-nos-pt-js" */),
  "component---src-pages-estrategia-esg-pt-js": () => import("./../../../src/pages/estrategia-ESG.pt.js" /* webpackChunkName: "component---src-pages-estrategia-esg-pt-js" */),
  "component---src-pages-ferramentas-pt-js": () => import("./../../../src/pages/ferramentas.pt.js" /* webpackChunkName: "component---src-pages-ferramentas-pt-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-o-seu-site-wordpress-esta-lento-e-com-bugs-pt-js": () => import("./../../../src/pages/o-seu-site-wordpress-esta-lento-e-com-bugs.pt.js" /* webpackChunkName: "component---src-pages-o-seu-site-wordpress-esta-lento-e-com-bugs-pt-js" */),
  "component---src-pages-politica-anticorrupcao-pt-js": () => import("./../../../src/pages/politica-anticorrupcao.pt.js" /* webpackChunkName: "component---src-pages-politica-anticorrupcao-pt-js" */),
  "component---src-pages-politica-gestao-reclamacoes-pt-js": () => import("./../../../src/pages/politica-gestao-reclamacoes.pt.js" /* webpackChunkName: "component---src-pages-politica-gestao-reclamacoes-pt-js" */),
  "component---src-pages-politica-privacidade-pt-js": () => import("./../../../src/pages/politica-privacidade.pt.js" /* webpackChunkName: "component---src-pages-politica-privacidade-pt-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-proteja-o-seu-site-wordpress-e-o-seu-negocio-pt-js": () => import("./../../../src/pages/proteja-o-seu-site-wordpress-e-o-seu-negocio.pt.js" /* webpackChunkName: "component---src-pages-proteja-o-seu-site-wordpress-e-o-seu-negocio-pt-js" */),
  "component---src-pages-services-en-js": () => import("./../../../src/pages/services.en.js" /* webpackChunkName: "component---src-pages-services-en-js" */),
  "component---src-pages-servicos-pt-js": () => import("./../../../src/pages/servicos.pt.js" /* webpackChunkName: "component---src-pages-servicos-pt-js" */),
  "component---src-pages-sobre-nos-pt-js": () => import("./../../../src/pages/sobre-nos.pt.js" /* webpackChunkName: "component---src-pages-sobre-nos-pt-js" */),
  "component---src-pages-strategy-esg-en-js": () => import("./../../../src/pages/strategy-ESG.en.js" /* webpackChunkName: "component---src-pages-strategy-esg-en-js" */),
  "component---src-pages-toolbox-en-js": () => import("./../../../src/pages/toolbox.en.js" /* webpackChunkName: "component---src-pages-toolbox-en-js" */),
  "component---src-pages-torne-o-seu-site-wordpress-mais-rapido-pt-js": () => import("./../../../src/pages/torne-o-seu-site-wordpress-mais-rapido.pt.js" /* webpackChunkName: "component---src-pages-torne-o-seu-site-wordpress-mais-rapido-pt-js" */),
  "component---src-pages-trabalho-pt-js": () => import("./../../../src/pages/trabalho.pt.js" /* webpackChunkName: "component---src-pages-trabalho-pt-js" */),
  "component---src-pages-work-en-js": () => import("./../../../src/pages/work.en.js" /* webpackChunkName: "component---src-pages-work-en-js" */)
}

